@import '../../../styles/stylingVariables.scss';

.activation-locations {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0 40px 40px 40px;

  .title {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 20px;
    margin-top: 10px;
    align-items: center;

    .typography {
      text-align: center;
    }

    .typography-body1 {
      margin-top: 10px;
    }

    .line {
      width: 100px;
      border-top: 2px solid $neutrals-50;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  .location-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: center;

    input {
      width: 100px;
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}