@import '../../../styles/variables.scss';

.delete-qr-code-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 50px;

  .delete-qr-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $secondary-color;
  }

  .delete-qr-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .delete-qr-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}