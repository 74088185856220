@import '../../../styles/stylingVariables.scss';

.settings-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  .settings-notifications,
  .settings-theme,
  .settings-language {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .styled-checkbox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

    }
  }
}