@import '../../styles/stylingVariables.scss';

.reset-password-container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .reset-left {
    width: 100%;
    height: 90%;
    border-radius: 15px;
    background-color: $neutrals-100;
    box-shadow: $primary-box-shadow;
    padding: 20px;

    .reset-left-content {
      height: 65%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .reset-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        input {
          width: 100%;
          height: 45px;
        }
      }

      .typography {
        text-align: center;
      }

      .create-account {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .logo {
      width: 100%;
      text-align: left;
    }


  }
}