.print-frame-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px;

  .print-frame-nav {
    width: 360px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }
}