@import '../../../../styles/stylingVariables.scss';

.qr-editor-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .qr-editor {
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin: 0 auto;
    justify-content: center;
    overflow-y: auto;

    .editor-schema-parser-page {
      display: flex;
      flex-direction: column;
      gap: 5px;
      overflow-y: auto;
    }

    .qr-editor-view-container {
      padding: 0 15px;
      border-left: 1px solid $neutrals-50;
      border-right: 1px solid $neutrals-50;
      overflow-y: auto;
    }

    .qr-editor-preview-container {
      flex-shrink: 0;
    }
  }
}