@import '../../styles/stylingVariables.scss';

.home-unauthorized-container {
  width: 80%;
  margin: 0 auto;
  white-space: pre-line;

  .create-account {
    margin-top: 40px;
  }
}