@import '../../../styles/stylingVariables.scss';

.qr-frame-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow-y: auto;
  gap: 15px;

  .qr-frame-editor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    gap: 20px;
    justify-content: center;
  }

  .qr-frame-add-field {
    padding: 0 15px;
    border-right: 1px solid grey;
  }

  .qr-frame-content {
    border-left: 1px solid $neutrals-50;
    border-right: 1px solid $neutrals-50;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .qr-frame-editor-fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}