@import '../../styles/stylingVariables.scss';

.edit-location-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 50px;

  .edit-location-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $secondary-90;
  }

  .typography-subheading3 {
    display: inline-block;

    &:after {
      content: '';
      display: inline-block;
    }

    .text-link {
      display: inline-block;

    }
  }

  .edit-location-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .edit-location-company {
      display: flex;
      flex-direction: column;

      .edit-location-company-label {
        color: $neutrals-30;
        margin-bottom: 5px;
        text-align: left;
        font-size: 14px;
      }
    }

    .important-message {
      display: flex;
      flex-direction: column;

      .typography {
        color: $warning-color;
      }
    }
  }

  .edit-location-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}