@import '../../styles/variables.scss';

.styled-progress-bar {
  width: 100%;
  max-width: 350px;

  .styled-progress-label {
    font-size: 12px;
    color: $primary-color-text;
  }

  .MuiLinearProgress-root {
    background-color: $tertiary-color;

    .MuiLinearProgress-bar {
      background-color: $secondary-color;
    }
  }
}