@import '../../../styles/stylingVariables.scss';

.register-activation-success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 50px;

  .header {}

  .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    svg {
      width: 80px;
      height: 80px;
      color: $success-color;
    }

    .register-activation-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}