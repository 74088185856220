$primary-box-shadow: 0 2px 11px rgba(0, 0, 0, .25);
$secondary-box-shadow: 0 8px 4px rgba(0, 0, 0, .25);
// convention: base = 100, darker > 100, lighter < 100, increment = 10

// primary
$primary-140: #332965;
$primary-130: #493B91;
$primary-120: #6251B8;
$primary-110: #5a49b1;
$primary-100: #7C6EC4; // base
$primary-90: #978BD0;
$primary-80: #BEB7E1;
$primary-70: #D8D4ED;

// secondary
$secondary-140: #14527B;
$secondary-130: #1A699E;
$secondary-120: #1F80C1;
$secondary-110: #2C96DD;
$secondary-100: #50A8E2; // base
$secondary-90: #73B9E8;
$secondary-80: #96CBEE;
$secondary-70: #B9DCF4;
$secondary-60: #DCEEF9;

// neutrals
$neutrals-110: #090A0B;
$neutrals-100: #24272d; // navbars 
$neutrals-90: #363A45; // backgrounds
$neutrals-80: #515667; // rest
$neutrals-70: #6C7389;
$neutrals-60: #8D92A5;
$neutrals-50: #AFB2C0; // borders/lines
$neutrals-40: #D1D3DB;
$neutrals-30: #e6e6e6; // text
$neutrals-20: #F4F4F6;
$neutrals-10: #FFFFFF;

// semantics
$info-color: #0078FB;
$warning-color: #FF9500;
$success-color: rgb(78, 187, 105);
$error-color: #FF3B30;

// extra
$transparent: rgba(0, 0, 0, 0);