@import '../../../styles/stylingVariables.scss';

.subscription-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .top {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: center;

    .subscription-name {
      color: $success-color;
    }
  }

  .current-subscription {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
  }

  .purchases-locations,
  .purchases-codes {
    height: 100%;
    width: 300px;
    padding-left: 20px;
    border-left: 1px solid $neutrals-50;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .warning {
      color: $error-color;
    }

    .btn {
      width: 250px;
    }
  }

  .invoices {
    margin: 20px 0;
    border-top: 2px solid $neutrals-50;
  }
}