@import './styles/stylingVariables.scss';

.App {
  text-align: center;
  background-color: $neutrals-90;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: $neutrals-30;

  .app-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
}

* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: 'Roboto';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  width: 100%;
  height: 100%;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  color: $neutrals-10;
  outline: none;
  box-shadow: none;
  padding: 0;
  border: none;
  background-color: $transparent;
}

a:hover,
a:active,
a:visited {
  color: $neutrals-10;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $neutrals-40;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-100;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-90;
}

// @media screen and (max-width: 415px) {
//   ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
//   }
// }

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0px;
    -webkit-appearance: none;
  }
}