@import '../../styles/stylingVariables.scss';

.feedback-expand-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 50px;

  .feedback-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $secondary-90;
  }

  .feedback-more-info {
    color: $neutrals-30;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;

    .field {
      width: 100%;
      display: flex;
      flex-direction: row;

      .title {
        color: $primary-90;
        width: 100px;
      }

      .value {
        width: calc(100% - 100px);
      }
    }
  }
}