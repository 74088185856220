@import '../../styles/stylingVariables.scss';

.upgrade-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .simple-card-container {
    flex-direction: column;
    background: linear-gradient(30deg, $neutrals-90 33%, $secondary-130 74%);

    .awesome-icon {
      width: 45px;
      height: 45px;
      margin-top: -40px;
      color: $warning-color;
    }

    .icon-btn {
      flex-direction: column;
      gap: 0px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}