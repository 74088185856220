@import '../../styles/variables.scss';

.styled-tabs {
  border-bottom: 1px solid $tertiary-color;
  width: 100%;
  display: flex;
  gap: 30px;

  .tab-btn {
    font-size: 16px;
    padding: 15px 0;
    color: $primary-color-text;
    border-bottom: 2px solid $transparent;
    height: 100%;
    transition: all 200ms;

    &:hover {
      color: $tertiary-color;
    }

    &.selected {
      font-weight: 500;
      color: $tertiary-color;
      border-bottom: 2px solid $tertiary-color;
      transition: all 200ms;
    }
  }
}