@import '../../styles/stylingVariables.scss';

.editor-img-container {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: $neutrals-80;
  border-radius: 10px;
  flex-direction: column;

  .editor-img-header {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .img-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .drop-img-outline {

      svg {
        width: 150px;
        height: 150px;
      }
    }

    .dropped-img-preview {
      max-height: 150px;
    }

    .dropped-img-name {
      color: $success-color;
      font-size: 14px;
    }

    .dropped-img-error {
      color: $error-color;

      .dropped-img-name {
        color: $error-color;
      }
    }

    .dropped-img-instructions {
      font-size: 13px;
      display: flex;
      flex-direction: column;
    }
  }
}