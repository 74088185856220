@import '../../styles/stylingVariables.scss';

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary-100;
  min-width: 35px;
  font-family: 'Roboto';
  min-height: 35px;
  font-weight: 500;
  font-size: 14px;
  gap: 5px;

  svg {
    color: $secondary-100;
  }

  &:hover,
  &:active {
    color: $secondary-80;
    transition: all 200ms;

    svg {
      transition: all 200ms;
      color: $secondary-80;
    }
  }

  &.filled {
    padding: 8px;
    background-color: $neutrals-80;
    border-radius: 50%;
    box-shadow: $primary-box-shadow;

    &:hover,
    &:active {
      background-color: $neutrals-70;
    }
  }

  &.outlined {
    min-width: 33px;
    min-height: 33px;
    border: 2px solid $secondary-100;
    border-radius: 50%;
    box-shadow: $primary-box-shadow;

    &:hover,
    &:active {
      border: 2px solid $secondary-80;
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}