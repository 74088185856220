@import '../../../styles/stylingVariables.scss';

.editor-live-preview {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
  flex-shrink: 0;
  gap: 10px;

  .preview {
    writing-mode: vertical-lr;
    text-orientation: upright;
  }

  .frame {
    width: 360px;
    flex-shrink: 0;
    max-height: 640px;
    height: 100%;
    border: 6px solid $neutrals-80;
    border-radius: 20px;

    .page-parser-container {
      border-radius: 16px;
    }
  }
}