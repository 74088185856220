@import '../../../styles/stylingVariables.scss';

.creator-setup-general-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  align-items: flex-start;

  .creator-field {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;

    &.creator-dropdown {
      .editing-dropdown {
        svg {
          color: $warning-color;
        }
      }

      .warning {
        margin-top: 10px;
      }
    }
  }

  .creator-template {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .creator-template-text {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .creator-template-card {
      width: 100%;
      display: flex;
      gap: 40px;
      font-size: 14px;

      .card-btn {
        flex-direction: column;
        justify-content: space-around;

        .icon {
          width: 20px;
          height: 20px;

          svg {
            color: $success-color;
          }
        }

        &.selected {
          border: 1px dashed $success-color;
        }

      }
    }
  }

}