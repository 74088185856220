.security-container {
  width: 100%;
  display: flex;

  .security-password-reset {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .btn {
      display: flex;
    }
  }
}