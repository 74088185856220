@import '../../styles/variables.scss';

.feedback-success {
  background-color: $primary-color-text;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100%;

  .feedback-success-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: $success-color;
    font-size: 40px;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  .feedback-success-text {
    line-height: 28px;
    font-size: 17px;
    color: $success-color;
  }
}