@import '../../styles/variables.scss';

.footer-container {
  color: $primary-color-text;
  opacity: 0.5;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $accent-color-primary;
  font-size: 12px;
}