@import '../../styles/stylingVariables.scss';

.color-picker {
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  background-color: $transparent;
  border-radius: 50%;
  border: 2px solid $secondary-90;

  input {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}