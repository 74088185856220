@import '../../../styles/stylingVariables.scss';

.activation-success {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 40px 40px 40px;

  .title {
    .typography {
      color: $success-color;
    }
  }

  svg {
    color: $success-color;
    width: 60px;
    height: 60px;
  }
}