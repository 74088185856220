@import '../../styles/stylingVariables.scss';

.simple-card-container {
  background-color: $neutrals-80;
  box-shadow: $primary-box-shadow;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-shrink: 0;

  &.interactive {
    cursor: pointer;
  }

  &.outlined {
    background-color: $transparent;
    border: 2px solid $primary-100;
  }

}