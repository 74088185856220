@import '../../styles/stylingVariables.scss';

.qr-form-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  border-top: 1px solid $neutrals-50;
  margin: 0;
  padding: 8px 0;
  justify-content: center;

  .text-link-name {
    font-size: 13px;

  }
}