//primary colors
$primary-color: #5a49b1;
$primary-color-var1: #7C6EC4;
$secondary-color: #50A8E2;
$secondary-color-var1: #2f4653;
$tertiary-color: #b6a6ca;

// borders
$primary-border-color: rgba(231, 227, 252, 0.22);
$primary-border-color-focused: rgba(145, 85, 253, 1);
// backgrounds
$primary-background: #15161a; // some backgrounds
$primary-background-var1: #323337;
$secondary-background: #1e1f25; // header, footer
$tertiary-background: #292d32; // some backgrounds
$tertiary-background-var1: #474952;

$transparent: rgba(0, 0, 0, 0);

$primary-color-box-shadow-background: 0 4px 15px rgba(0, 0, 0, .25);
$secondary-color-box-shadow-background: 0 8px 4px rgba(0, 0, 0, .25);

//semantics
$info-color: #0078FB;
$warning-color: #FF9500;
$success-color: #4ebb69;
$error-color: #FF3B30;

$primary-color-text: #e6e6e6;
$secondary-color-text: rgba(138, 137, 145, 0.384);
$color-text-links: #9155fd;
$color-text-links-hover: #5a49b1;

$accent-color-primary: #858591c4;