@import '../../styles/stylingVariables.scss';

.nav-link {
  text-decoration: none;
  border: none;
  background-color: $transparent;
  color: $neutrals-30;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 5px;
  height: 50px;

  .nav-link-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 0 25px;
    gap: 15px;
    font-family: 'Roboto';
  }

  svg {
    width: 28px;
    height: 28px;
  }

  &:visited {
    color: $neutrals-30;
  }

  &:hover {
    color: $neutrals-30;
    background-color: $primary-100;
    transition: all 200ms;
    box-shadow: $primary-box-shadow;
  }

  &.active {
    color: $neutrals-30;
    background-color: $primary-110;
    font-weight: 500;
    box-shadow: $primary-box-shadow;
  }
}