@import '../../../styles/variables.scss';

.creator-setup {
  gap: 15px;

  .creator-setup-progress {
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .creator-setup-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}