@import '../../styles/stylingVariables.scss';

.card-btn {
  background-color: $neutrals-80;
  box-shadow: $primary-box-shadow;
  border: 1px dashed $secondary-100;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  transition: all 0.2s;
  color: $secondary-100;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: $neutrals-70;
    transition: all 0.2s;
    color: $secondary-80;
  }

  &:disabled {
    background-color: $neutrals-90;
    cursor: not-allowed;
    opacity: 0.4;
  }
}