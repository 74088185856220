@import '../../styles/stylingVariables.scss';

.page-container {
  background-color: $neutrals-90;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 5px;
  overflow-y: auto;
}