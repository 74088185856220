@import '../../styles/stylingVariables.scss';

.text-link {
  display: flex;
  flex-direction: row;

  .text-link-name {
    font-size: 14px;
    color: $secondary-100;
    font-weight: 500;
  }


  &:hover {
    .text-link-name {
      color: $secondary-80;
      transition: all 0.2s;
    }
  }
}