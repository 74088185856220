@import '../../styles/stylingVariables.scss';

.subscribe-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .simple-card-container {
    padding: 10px;
    background: linear-gradient(30deg, $neutrals-100 3%, $secondary-130 99%);

  }

  .subscription-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 20px;

    svg {
      width: 100px;
      height: 100px;
      margin-top: -60px;
      color: $warning-color;
    }

    .subscription-text {
      color: $neutrals-30;
    }
  }
}