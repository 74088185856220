@import '../../../../styles/stylingVariables.scss';

.droppable-area {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .draggable-field {
    width: 372px;
    border: 1px dashed $neutrals-50;
    border-radius: 10px;
  }

  .navigation {
    width: 372px;
  }
}