.MuiAlert-root {
  .MuiAlert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiAlert-message {
    text-align: left;
    white-space: pre-line;
  }

  .MuiAlert-action {
    display: flex;
    padding: 0;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
  }
}