@import '../../styles/stylingVariables.scss';

.login-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .login-left {
    height: 90%;
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $primary-100;
    box-shadow: $primary-box-shadow;
    padding: 20px;

    .login-left-content {
      height: 65%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .typography {
        text-align: center;
      }

      .create-account {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .logo {
      width: 100%;
      text-align: left;
    }
  }

  .login-right {
    height: 90%;
    width: 60%;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: $neutrals-100;
    padding: 20px;
    gap: 50px;

    .title {
      .typography {
        text-align: center;
      }
    }

    .login-fields {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .text-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;
      }

      input {
        height: 45px;
      }

      .input-icon {
        margin-top: 5px;
      }
    }

    .login-integrations {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .line {
        width: 100px;
        border-top: 2px solid $neutrals-50;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    width: 100%;
  }
}

@media only screen and (max-width: 415px) {
  .login-container {}
}