@import '../../styles/stylingVariables.scss';

.delete-feedback-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 50px;

  .edit-feedback-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $secondary-90;

    .typography-subheading3 {
      display: inline-block;

      &:after {
        content: '';
        display: inline-block;
      }

      .text-link {
        display: inline-block;

      }
    }
  }

  .feedback-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}