@import '../../styles/stylingVariables.scss';

.storybook-container {
  background-color: $neutrals-90;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .navbar {
    width: 100%;
    height: 60px;
    background-color: $neutrals-100;
    box-shadow: $primary-box-shadow;
  }
}