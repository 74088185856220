@import '../../../styles/stylingVariables.scss';

.reset-set-new-pass-container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .reset-left-content {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    background-color: $neutrals-100;
    box-shadow: $primary-box-shadow;
    padding: 20px;
    margin: 0 auto;
    padding-bottom: 70px;

    .reset-fields {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      input {
        width: 100%;
        height: 45px;
      }

      .icon-btn {
        margin-top: 5px;
      }

      .pass-details {
        font-size: 12px;

        .checked {
          color: $success-color;
        }

        .unchecked {
          color: $error-color;
        }
      }
    }

    .typography {
      text-align: center;
    }

    .create-account {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .logo {
    width: 100%;
    text-align: left;
  }
}