@import '../../../styles/stylingVariables.scss';

.reset-set-new-pass-success {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 50px;

  .reset-success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $success-color;

    svg {
      width: 80px;
      height: 80px;
    }
  }
}