@import '../../styles/stylingVariables.scss';

.primary-btn {
  text-decoration: none;
  background-color: $primary-110;
  padding: 8px 25px;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: $primary-box-shadow;
  min-width: 120px;
  min-height: 40px;
  // border: 2px solid $primary-110;

  &:hover {
    background-color: $primary-100;
    // border: 2px solid $primary-100;
    color: $neutrals-30;
    transition: all 200ms;
  }

  &:disabled {
    opacity: 0.3;
    background-color: $primary-110;
    cursor: not-allowed;
  }
}

@media only screen and (max-width: 415px) {
  .primary-btn {
    min-height: 45px;
  }
}