@import '../../../styles/stylingVariables.scss';

.change-sub-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 30px;

  .change-sub-header {
    color: $neutrals-30;
  }

  .change-sub-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    .card-btn {

      .icon {
        width: 20px;
        height: 20px;

        svg {
          color: $success-color;
        }
      }

      &.selected {
        border: 1px dashed $success-color;
      }
    }

  }

  .change-sub-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
}