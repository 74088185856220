@import '../../../styles/stylingVariables.scss';

.activation-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 40px 40px 40px;
  justify-content: space-between;

  .title {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    align-items: center;

    .typography {
      text-align: center;
    }

    .typography-body1 {
      margin-top: 10px;
    }

    .line {
      width: 100px;
      border-top: 2px solid $neutrals-50;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    input {
      height: 45px;
    }
  }
}