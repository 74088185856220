@import '../../styles/variables.scss';

.qr-code-wrong {
  width: 100%;
  height: 100%;
  font-size: 17px;
  color: $error-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 10px;
  background-color: white;
  line-height: 30px;

  svg {
    width: 80px;
    height: 80px;
  }
}