.quick-form-preview-container {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin: 0 auto;

  .page-parser-container {
    max-height: 628px;
  }
}