@import '../../../styles/stylingVariables.scss';

.buy-codes-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 30px;

  .buy-codes-info {
    color: $neutrals-30;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    input {
      width: 150px;
    }

    .pay {
      font-weight: 600;
    }
  }

  .buy-codes-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
}