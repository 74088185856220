@import '../../styles/stylingVariables.scss';

.styled-checkbox {
  .MuiFormControlLabel-root {


    .MuiCheckbox-root {

      .MuiSvgIcon-root {
        color: $secondary-100;
        width: 24px;
        height: 24px;
      }
    }

    .MuiFormControlLabel-label {
      color: $neutrals-30;
      text-align: left;
      display: flex;
      gap: 5px;
      font-size: 14px;
      align-items: center;
      font-family: 'Roboto';
    }
  }
}