@import '../../styles/stylingVariables.scss';

.register-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .register-left {
    height: 90%;
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $primary-100;
    box-shadow: $primary-box-shadow;
    padding: 20px;

    .register-left-content {
      height: 65%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .typography {
        text-align: center;
      }

      .login {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .logo {
      width: 100%;
      text-align: left;
    }
  }

  .register-right {
    height: 90%;
    width: 60%;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: $neutrals-100;
    padding: 20px;
    gap: 50px;

    .title {
      .typography {
        text-align: center;
      }
    }

    .register-fields {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      input {
        height: 45px;
      }

      .input-icon {
        margin-top: 5px;
      }

      .pass-details {
        font-size: 12px;

        .checked {
          color: $success-color;
        }

        .unchecked {
          color: $error-color;
        }
      }
    }

    .register-integrations {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .line {
        width: 100px;
        border-top: 2px solid $neutrals-50;
      }
    }
  }
}

@media only screen and (max-width: 1536px) {
  .register-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1366px) {
  .register-container {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .register-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: auto;
    min-height: 0;

    .register-left {
      width: 100%;
      padding: 20px;
      border-radius: 0;
      min-height: 0;
      height: 100%;

      .register-left-content {
        height: 100%;
      }
    }

    .register-right {
      width: 100%;
      height: auto;
      flex-shrink: 0;
      padding: 20px;
      min-height: 0;
      border-radius: 0;
    }
  }
}