@import '../../styles/stylingVariables.scss';

.reset-password-success {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .reset-success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $success-color;

    svg {
      width: 80px;
      height: 80px;
    }
  }
}