@import '../../../styles/stylingVariables.scss';

.account-activation-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .activation-left {
    height: 90%;
    width: 30%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $primary-110;
    box-shadow: $primary-box-shadow;
    padding: 20px;

    .activation-left-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;

      .middle-message {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;

        svg {
          color: $success-color;
          width: 60px;
          height: 60px;
        }

        .typography {
          text-align: center;

          &.success {
            color: $success-color;
          }
        }
      }

      .activation-progress {
        margin-top: 70px;
      }
    }

    .logo {
      width: 100%;
      text-align: left;
    }
  }

  .activation-right {
    height: 90%;
    width: 70%;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: $neutrals-100;
    padding: 20px;
    gap: 80px;

    .nav-btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}