.qr-publish-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  .qr-publish-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 40px;
  }
}