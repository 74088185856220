@import '../../styles/stylingVariables.scss';

.register-success {
  width: 80%;
  color: $neutrals-30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  line-height: 28px;
  height: 90%;
  margin: 0 auto;
  border-radius: 20px;
  padding: 40px;

  .register-success-top {
    font-size: 36px;
    color: $success-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    svg {
      color: $success-color;
      width: 80px;
      height: 80px;
    }
  }

  .register-success-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .register-success {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
}

@media only screen and (max-width: 415px) {
  .register-success {
    padding: 20px;

    .register-success-text {
      font-size: 14px;
    }
  }
}