 @import '../../styles/stylingVariables.scss';

 .styled-rating {
   max-width: 350px;
   width: 100%;
   min-width: 320px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 5px;

   .styled-rating-label {
     width: 100%;
     color: $neutrals-30;
     font-size: 14px;
     text-align: left;
   }

   .MuiRating-root {
     color: $primary-100;
     font-size: 30px;
     width: 100%;

     label {
       width: 33px;
       flex-shrink: 0;
     }
   }

   .MuiRating-iconEmpty {
     color: $primary-100;
   }
 }

 @media only screen and (max-width: 360px) {
   .styled-rating {
     min-width: 280px;
     max-width: 300px;

     .MuiRating-root {

       label {
         width: 30px;
       }
     }
   }
 }