@import '../../../styles/stylingVariables.scss';

.resend-activation-success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 50px;

  .title {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .typography {
      color: $success-color;
    }
  }

  svg {
    color: $success-color;
    width: 60px;
    height: 60px;
    margin-bottom: 100px;
  }
}