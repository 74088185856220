@import '../../styles/stylingVariables.scss';

.feedback-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .feedback-table {
    margin-top: 15px;
    border-top: 3px solid $neutrals-50;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .feedback-filters {
    width: 90%;
    margin-top: 30px;
    display: flex;
    gap: 30px;
    align-items: center;

    .feedback-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .feedback-search {
      width: 250px;
    }

    .styled-input {
      gap: 0;
    }
  }

  .feedback-cards {
    border-top: 3px solid $neutrals-50;
    display: flex;
    margin-top: 15px;
    padding: 15px 0;
    gap: 5px;
    height: calc(100% - 120px);
    flex-wrap: wrap;
    overflow-y: hidden;

    .feedback-card-headers {
      width: calc(100% - 20px);
      display: flex;
      padding: 0 10px;

      .feedback-card-descriptors {
        display: flex;
        width: calc(100% - 180px);
      }

      .feedback-card-location {
        width: 40%;
      }

      .feedback-card-company,
      .feedback-card-date,
      .feedback-card-rating {
        width: 25%;
        text-align: left;
      }

      .feedback-card-location {
        .typography {
          color: $primary-90;
          font-weight: 500;
        }
      }

      .feedback-card-actions {
        width: 180px;

        .typography {
          color: $secondary-90;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .feedback-card-rows {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 20px);
      overflow-y: auto;

      .feedback-card {
        font-size: 14px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;

        .feedback-card-data {
          display: flex;
          flex-direction: row;
          width: calc(100% - 180px);

          .feedback-location {
            width: 40%;
            color: $primary-90;
            font-weight: 500;
          }

          .feedback-company,
          .feedback-date,
          .feedback-rating {
            width: 25%;
          }
        }

        .feedback-card-actions {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }

  }
}