@import '../../styles/stylingVariables.scss';

.MuiTableContainer-root {
  background-color: $transparent;

  .MuiTableHead-root {

    .MuiTableCell-root {
      color: $neutrals-30;
      background-color: $neutrals-80;
    }

  }

  .MuiTableCell-root {
    color: $neutrals-30;

    .primary-btn {
      min-width: 100px;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .actions {
        display: flex;
        gap: 20px;
      }
    }
  }
}