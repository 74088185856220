@import '../../styles/stylingVariables.scss';

.edit-company-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 50px;


  .edit-company-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .edit-company-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .edit-company-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}