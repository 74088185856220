@import '../../styles/stylingVariables.scss';

.styled-toggle {
  position: relative;
  width: 52px;
  height: 28px;
  line-height: 28px;
  display: inline-block;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    width: 52px;
    height: 28px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $secondary-100;
    background-color: $transparent;
    box-shadow: $primary-box-shadow;
    transition: 0.2s;
    border-radius: 30px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    top: 2px;
    background-color: $secondary-100;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked+span {
    background-color: $secondary-70;
  }

  input:checked+span:before {
    transform: translateX(24px);
  }
}