@import '../../../../styles/stylingVariables.scss';

.editor-overall-set-style {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  background-color: $neutrals-80;
  border-radius: 10px;
  gap: 10px;

  .editor-styling-header {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .styling {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
  }
}