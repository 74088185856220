@import '../../../styles/stylingVariables.scss';

.frame-preview-container {
  width: 360px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $neutrals-50;
  gap: 10px;
}