@import '../../styles/stylingVariables.scss';

.qr-code-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .subtitle {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-family: 'Open Sans';

    .powered {
      font-family: 'Open Sans';
    }

    .maoni {
      color: $primary-90;
      font-weight: 500;
      font-family: 'Open Sans';
    }
  }
}