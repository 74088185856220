@import '../../styles/stylingVariables.scss';

.locations-container {
  .typography-subheading3 {
    display: block;

    .text-link {
      width: 200px;
      display: inline-block;
    }
  }

  .locations-top-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .locations-filters {
      width: 90%;
      display: flex;
      gap: 30px;
      align-items: center;

      .locations-dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .locations-search {
        width: 250px;
      }

      .styled-input {
        gap: 0;
      }
    }

    .locations-add-new {
      width: 150px;
    }
  }

  .locations-cards {
    border-top: 3px solid $neutrals-50;
    border-radius: 2px;
    display: flex;
    margin-top: 15px;
    padding: 15px 0;
    gap: 5px;
    height: 100%;
    flex-wrap: wrap;
    overflow-y: hidden;

    &.not-found {
      font-style: italic;
      color: $neutrals-50;
    }

    .location-card-headers {
      width: calc(100% - 10px);
      display: flex;
      padding: 0 10px;

      .location-card-descriptors {
        display: flex;
        width: calc(100% - 100px);
      }

      .location-card-name,
      .location-card-company {
        width: 40%;
        text-align: left;
      }

      .location-card-name {
        .typography {
          color: $primary-90;
          font-weight: 500;
        }
      }

      .location-card-actions {
        width: 100px;

        .typography {
          color: $secondary-90;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .location-card-rows {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .location-card {
        font-size: 14px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;

        .location-card-data {
          display: flex;
          flex-direction: row;
          width: calc(100% - 100px);

          .card-location-name {
            width: 40%;
            color: $primary-90;
            font-weight: 500;
          }

          .card-location-company {
            width: 40%;
          }
        }

        .location-card-actions {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }

  }
}

.error-locations {
  padding: 50px;
}