@import '../../../../styles/stylingVariables.scss';

.editor-schema-add-field {
  width: 300px;
  display: flex;
  padding: 10px;
  background-color: $neutrals-80;
  border-radius: 10px;
  flex-direction: column;

  .editor-add-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .editor-schema-add-field-dropdown {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 15px 0;

    .classic-dropdown {
      width: 150px;
    }
  }

  .add-warning {
    height: 30px;
  }
}