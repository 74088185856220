@import '../../styles/stylingVariables.scss';

.styled-input {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  .styled-input-limit {
    position: absolute;
    right: 5px;
    bottom: 2px;
    font-size: 9px;
    color: $neutrals-60;
  }

  .styled-input-label {
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    color: $neutrals-30;
    white-space: pre-line;
  }

  input,
  textarea {
    height: 100%;
    border: 2px solid $neutrals-50;
    color: $neutrals-30;
    background-color: $transparent;
    outline: none;
    padding: 10px;
    height: 38px;
    box-shadow: $primary-box-shadow;
    border-radius: 10px;
    font-size: 14px;

    &:focus {
      border: 2px solid $primary-100;
      outline: none;
      transition: all 250ms;
      transition: all 250ms;
    }

    &::after {
      border: none;
    }

    &::placeholder {
      font-weight: 300;
      font-style: italic;
      opacity: 0.3;
      color: $neutrals-30;
      font-weight: 300;
      font-size: 14px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  textarea {
    max-height: 200px;
    height: auto;
    min-height: 100px;
    resize: none;
  }
}

.styled-input-icon {

  .styled-input-input {
    display: flex;
    flex-direction: row;
    width: 100%;

    input {
      width: 100%;
      padding-right: 45px;
    }
  }

  .input-icon {
    width: 35px;
    height: 35px;
    margin-top: 2px;
    margin-left: -38px;
  }
}

.styled-input.editor {

  input,
  textarea {
    box-shadow: none;
  }
}

@media only screen and (max-width: 415px) {
  .styled-input {
    input {
      height: 42px;
    }
  }
}