@import '../../styles/stylingVariables.scss';

.typography {
  font-family: 'Roboto';

  &-h1 {
    font-size: 30px;
    line-height: 60px;
    color: $neutrals-30;
    text-align: left;
    font-weight: 400;
    font-family: 'Lato';
    margin: 6px 0;
  }

  &-h2 {
    font-size: 27px;
    font-weight: 400;
    line-height: 30px;
    color: $neutrals-30;
    text-align: left;
  }

  &-h3 {
    font-size: 20px;
    line-height: 30px;
    color: $neutrals-30;
    text-align: center;
    font-weight: 400;
    margin: 3px 0;
  }

  &-subheading1 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: $neutrals-30;
    text-align: left;
    margin: 10px 0;
  }

  &-subheading2 {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $neutrals-40;
    text-align: left;
    margin: 5px 0;
    gap: 10px;
    align-items: center;
  }

  &-subheading3 {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $neutrals-30;
    text-align: left;
    margin: 5px 0;

  }

  &-body1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: $neutrals-30;
    align-items: center;
    font-size: 14px;
  }

  &-body2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 12px;
  }

  &.warning {
    color: $warning-color;
  }

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }
}