@import '../../../styles/stylingVariables.scss';

.reset-pass-fail-token {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  svg {
    width: 80px;
    height: 80px;
    color: $error-color;
  }

  .reset-pass-fail-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}