@import '../../styles/stylingVariables.scss';

.classic-dropdown {
  width: 250px;
  padding: 5px;
  background-color: $transparent;
  border: 2px solid $neutrals-50;
  border-radius: 10px;
  text-align: left;
  font-family: "Roboto";
  max-width: 300px;
  color: $neutrals-30;
  height: 38px;
  box-shadow: $primary-box-shadow;

  &.Mui-disabled {
    color: $neutrals-30 !important;
    -webkit-text-fill-color: $neutrals-30;
    opacity: 0.5;
  }

  .MuiSelect-icon {
    color: $secondary-100 !important;
    -webkit-text-fill-color: $secondary-100;

    &.Mui-disabled {
      color: $secondary-100 !important;
      -webkit-text-fill-color: $secondary-100;
      opacity: 0.5;
    }
  }

  &:hover {
    .MuiSelect-icon {
      color: $secondary-90 !important;
    }
  }

  .classic-dropdown-list-item-text {
    width: 100%;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $neutrals-30;
  }

  &.opened {
    border: 2px solid $primary-100;
    color: $neutrals-30;
  }

  .MuiSelect-select {
    padding: 5px;
    color: $neutrals-30;
    font-size: 14px;

    &.Mui-disabled {
      color: $neutrals-30 !important;
      -webkit-text-fill-color: $neutrals-30;
      opacity: 0.5;
    }

    .placeholder {
      font-size: 14px;
      font-weight: 300;
      font-style: italic;
      opacity: 0.3;
    }
  }

  .MuiSvgIcon-root {
    color: $neutrals-30;
  }
}

.classic-dropdown-menu-container {
  color: $neutrals-30;

  .classic-dropdown-list-item-text {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $neutrals-30;
  }

  .MuiPaper-root {
    background-color: $neutrals-80;
    color: $neutrals-30;

    .MuiList-root {
      max-width: 250px;
      color: $neutrals-30;
    }

    .classic-dropdown-list-item {
      padding: 7px 10px;
      color: $neutrals-30;

      &:hover {
        background-color: $primary-90;
      }

      &:focus {
        background-color: $primary-100;
      }
    }
  }
}

@media only screen and (max-width: 415px) {
  .classic-dropdown {
    min-height: 42px;
  }
}