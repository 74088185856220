@import '../../styles/stylingVariables.scss';

.styled-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .MuiBox-root {
    box-shadow: $primary-box-shadow;
    border-radius: 10px;
    background-color: $neutrals-90;
    border: none;
    outline: none;
  }
}