@import '../../styles/stylingVariables.scss';

.editor-qr {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: $neutrals-80;
  border-radius: 10px;
  flex-direction: column;

  .editor-qr-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .qr-content {
    margin-top: 10px;
  }
}