@import '../../styles/stylingVariables.scss';

.circular-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .circular-progress-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .progress {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .progress-name {
        z-index: 1;
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
      }

      .progress-number {
        z-index: 1;
        font-size: 20px;
        border: 2px solid $secondary-100;
        border-radius: 50%;
        padding: 5px;
        width: 35px;
        height: 35px;
        line-height: 22px;
        background-color: $neutrals-50;
        color: $secondary-100;
        transition: all 0.2s;
      }

      &.checked {
        svg {
          padding: 0;
          margin-top: -1px;
          margin-left: -2px;
          width: 25px;
          height: 25px;
          color: $primary-100;
        }

        .progress-number {
          background-color: $secondary-100;
          transition: color 0.2s;
        }
      }

      &.current {
        position: relative;

        .progress-number {
          font-weight: 600;
          background-color: $secondary-100;
          color: $primary-110;
          transition: all 0.2s;
        }

        .progress-name {
          font-weight: 600;
          color: $secondary-100;
          transition: all 0.2s;
        }
      }
    }
  }

  .line {
    border-bottom: 2px solid $secondary-100;
    width: calc(100% - 70px);
    margin: 0 auto;
    margin-bottom: -18px;
  }

  &.vertical {
    margin-top: 100px;
    transform: rotate(90deg);

    .progress {
      .progress-name {
        width: 100px;
        transform: rotate(270deg);
        margin-top: -100px;
        text-align: left;
      }

      svg {
        transform: rotate(0deg);
      }

      .progress-number {
        background-color: $primary-110;
        transform: rotate(270deg);
      }
    }
  }
}