@import '../../styles/stylingVariables.scss';

.editor-rating-container {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: $neutrals-80;
  border-radius: 10px;
  flex-direction: column;

  .editor-rating-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutrals-50;
  }

  .editor-rating-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 15px;

    .edit-settings {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .field {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .classic-dropdown {
        width: 80px;
      }
    }
  }

  .edit-colors {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
  }
}